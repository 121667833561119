<template>
  <div>
    <h3 ref="intro">
      What is a Triangle?
    </h3>
    <br>
    <p>
      A triangle is a shape that is obtained by connecting three points on the same plane with straight lines. <br><br>

      The three points are called vertices of the triangle, and three straight lines
      connecting these points are called sides of the triangle.
    </p>
    <h3 ref="intro">
      Area of a Triangle
    </h3>
    <br>
    <p>
      The area of a triangle is the span of the region enclosed between the three sides of the triangle.
      <br> <br>
      For a triangle with height \(h\) and base \(b\), the area is given by
      $$A = \frac{1}{2} \times b \times h$$
    </p>

    <h3 ref="pg">
      MagicGraph | Drawing a Triangle
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      This MagicGraph offers a visual-interactive lesson on drawing a triangle.
      <br><br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      Tap on <i class="redoer ma-1" /> button  to go to the next step. Tap on <i class="undoer ma-1" /> button to go to the previous step.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h3 class="edliy-text-about">
          Quick Tips: Tap on <i class="stater ma-1" /> button to see the sides and angles of the triangle. Tap on <i class="eraser ma-1" /> button to erase everything and start over.
        </h3>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Tools',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Drawing a Triangle ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Drawing a Triangle',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about drawing a triangle.'}
                ]
        }
   },
}
</script>
