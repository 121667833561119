import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.polygon.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //Layering
  brd1.options.layer['point']=6
  brd1.options.layer['segment']=4
  makeResponsive(brd1);
  placeTitle(brd1, 'Draw a Triangle', '(Click to Add Points to the Board)');
  placeLogo(brd1);
  var analytics =placeImage(brd1, '/assets/statistics.svg', 1, -9.25, 1.5, 0);
  analytics.setLabel('Extreme Analytics')
  analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  analytics.on('over', function () {this.label.setAttribute({visible:true});});
  analytics.on('out', function () {this.label.setAttribute({visible:false});});

  var erase =placeImage(brd1, '/assets/eraser.svg', -2.5, -9.25, 1.5, 0);
  erase.setLabel('Tap to Erase & Restart')
  erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  erase.on('over', function () {this.label.setAttribute({visible:true});});
  erase.on('out', function () {this.label.setAttribute({visible:false});});
  //placeDash(board, Pt1, Pt2, width, cols)
  //brd1.create('axis', [[-1, 0], [1, 0]], {strokeWidth: 2, strokeColor: 'red'});
  brd1.suspendUpdate();
  var name = placeMiddleText(brd1, 6.5, 14, '');
  name.setAttribute({color:'green'});
  var des = placeMiddleText(brd1, 6.5, 14, '');
  des.setAttribute({color:'green'});
  brd1.options.layer['image'] =14;
  var names=['A', 'B', 'C'];
 var i =0; var g; var h; var txt1; var txt2; var txt3; var txt4; var txt5; var txt6; var p, q, r, x, y, z;
  var Points=[];
  brd1.on('up',
            function (evt)
            {
            if(i<3 && !erase.mouseover)
            {
            Points[i] = brd1.create('point', brd1.getUsrCoordsOfMouse(evt), {name:names[i],size:4, snapToGrid:true});
            }
            i+=1;
            brd1.update();
            });
  //erase.on('down', brd1.removeObject(Points[0]));
  brd1.on('up', function()
  {
    if(i==2)
    {
    g =brd1.create('segment',[Points[0], Points[1]] , {name:'g', strokeColor:'black', visible:false});
    }
  }
)
brd1.on('up', function()
{
  if(i==3)
  {
   h =brd1.create('polygon',[Points[0], Points[1], Points[2]] , {name:'h', borders:{strokeColor:'black'}, fillColor:'white', fillOpacity:1, strokeColor:'red', strokeWidth:1});
   p=brd1.create('segment',[Points[0], Points[1]] , {name:'p', visible:false, strokeColor:'blue', strokeWidth:0});
   q=brd1.create('segment',[Points[1], Points[2]] , {name:'q', visible:false, strokeColor:'blue', strokeWidth:0});
   r=brd1.create('segment',[Points[0], Points[2]] , {name:'r', visible:false, strokeColor:'blue', strokeWidth:0});
   x =brd1.create('nonreflexangle',[Points[0], Points[1], Points[2]] , {radius:1, name:'a', visible:false, strokeColor:'blue', strokeWidth:1});
   y =brd1.create('nonreflexangle',[Points[1], Points[0], Points[2]] , {radius:1, name:'b', visible:false, strokeColor:'blue', strokeWidth:1});
   z =brd1.create('nonreflexangle',[Points[0], Points[2], Points[1]] , {radius:1, name:'c', visible:false, strokeColor:'blue', strokeWidth:1});
   txt1 = placeRightText(brd1, -4, 6.5, function(){return 'AB = ' + (p.L()).toFixed(2);});
   txt1.setAttribute({visible:false, color:'black'});
   txt2 = placeMiddleText(brd1, 0, 6.5, function(){return 'BC = ' + (q.L()).toFixed(2);});
   txt2.setAttribute({visible:false, color:'red'});
   txt3 = placeLeftText(brd1,  4, 6.5, function(){return 'CA = ' + (r.L()).toFixed(2);});
   txt3.setAttribute({visible:false, color:'green'});
   /////////////
   txt4 = placeRightText(brd1, -4, 5.5, function(){return 'a = ' + (x.Value()*180/Math.PI).toFixed(2);});
   txt4.setAttribute({visible:false, color:'black'});
   txt5 = placeMiddleText(brd1, 0, 5.5, function(){return 'b = ' + (y.Value()*180/Math.PI).toFixed(2);});
   txt5.setAttribute({visible:false, color:'red'});
   txt6 = placeLeftText(brd1,  4, 5.5, function(){return 'c = ' + (z.Value()*180/Math.PI).toFixed(2);});
   txt6.setAttribute({visible:false, color:'green'});
  //var k =brd1.create('segment',[Points[0], Points[2]] , {name:'k', strokeColor:'red', strokeWidth:1});
  }
}
)
 erase.on('down', function()
 {
   brd1.removeObject([Points, g, h, p, q, r, x, y, z, txt1, txt2, txt3]);
   txt1.setAttribute({visible:false});
   txt2.setAttribute({visible:false});
   txt3.setAttribute({visible:false});
   txt4.setAttribute({visible:false});
   txt5.setAttribute({visible:false});
   txt6.setAttribute({visible:false});
 });
 erase.on('up', function()
 {
  i=0;
  if(!g)
  {var g;}
  if(!h){var h;}
  if(!Points){var Points=[];}
 });
 analytics.on('down', function()
 {
 txt1.setAttribute({visible:true});
 txt2.setAttribute({visible:true});
 txt3.setAttribute({visible:true});
 txt4.setAttribute({visible:true});
 txt5.setAttribute({visible:true});
 txt6.setAttribute({visible:true});
 x.setAttribute({visible:true});
 y.setAttribute({visible:true});
 z.setAttribute({visible:true});
});
  brd1.unsuspendUpdate();
},
}
export default Boxes;
